window.$ = $;
window.jQuery = $;

import 'intersection-observer';
import './menu';
import 'add-to-calendar-button';
import './_lazy-image';
import './_section-text.js';
import './_banner.js';
import './_link-shortcuts.js';
import './_scroll-to';
import './_reports-and-presentation.js';
import './_accordion.js';
import './_financial-calendar.js';
import './_publications.js';
import './_search.js';
import './page-news-archive.js';
import './page-press-releases.js';
import './_block-bar-chart.js';
import './_slider.js';
import './_application-form.js';
import './_person.js';
import './_projects.js';
import './_timeline.js';
import './_videos.js';
import './_block-form.js';