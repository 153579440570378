import Swipe from 'swipejs';

// Setup slider and prev/next-buttons
let sliders = document.querySelectorAll('.media-wrap.has-slides');
if(sliders.length > 0) {
    Array.prototype.forEach.call(sliders, function (el, i) {
    let animate = el.getAttribute('data-animate');
    let autoSpeed = el.getAttribute('data-auto-speed');
    let navigation = el.querySelector('.slider-navigation');
    let navChildren = navigation
      ? navigation.querySelectorAll('div.nav-link')
      : [];
    let speed = 500;

    Window['slider-' + i] = new Swipe(el, {
      startSlide: 0,
      draggable: true,
      continuous: true,
      disableScroll: false,
      auto: animate ? parseInt(autoSpeed) || 4000 : 0,
      speed: speed,
      callback: function (index) {
        if (navigation == null) {
          return;
        }

        Array.prototype.forEach.call(navChildren, function (child) {
          child.classList.remove('active');
        });
        if (navChildren.length > 0) {
          navChildren[index].classList.add('active');
        }
      },
    });

    Array.prototype.forEach.call(navChildren, function (child, index) {
      child.addEventListener('click', function () {
        Window['slider-' + i].slide(index, speed);
      });
    });
  });

  let slider_prev = document.querySelectorAll('.media-wrap button.slider-prev');
  Array.prototype.forEach.call(slider_prev, function (el, i) {
    el.addEventListener('click', function () {
      Window['slider-' + i].prev();
    });
  });

  let slider_next = document.querySelectorAll('.media-wrap button.slider-next');
  Array.prototype.forEach.call(slider_next, function (el, i) {
    el.addEventListener('click', function () {
      Window['slider-' + i].next();
    });
  });

  let sliderPoints = document.querySelectorAll(
    '.media-wrap .slider-navigation .slide-info'
  );
  if (sliderPoints && sliderPoints.length > 0) {
    sliderPoints.forEach((point) => {
      point.addEventListener('mouseenter', (e) => {
        sliderPoints.forEach((item) => {
          item.classList.add('fade-out');
        });
        point.classList.remove('fade-out');
      });
      point.addEventListener('mouseleave', (e) => {
        sliderPoints.forEach((item) => {
          item.classList.remove('fade-out');
        });
        //point.classList.add('fade-out');
      });
    });
  }
}