  // Click to play video
  let videos = document.querySelectorAll('.media-wrap video');
  Array.prototype.forEach.call(videos, function (el, i) {
    el.addEventListener('click', function () {
      if (this.paused === true) {
        this.play();
        this.setAttribute('controls', 'true');
        this.nextElementSibling.style.display = 'none';
      } else if (this.paused === false) {
        this.pause();
        this.removeAttribute('controls');
        this.nextElementSibling.style.display = 'block';
      }
    });
  });