// Publication tabs
let $publications = $('.section__publications');
if($publications.length > 0) {
  $publications.each(function (index, publication) {
    let $publication = $(publication);
    let $tabs = $publication.find('.tabs');
    let $contentBlocks = $publication.find('.content > div');

    $tabs.find('a').click(function (e) {
      e.preventDefault();

      $tabs.find('li').removeClass('active');
      $contentBlocks.removeClass('active');

      let $parent = $(this).parent();
      let index = $parent.index();

      $parent.addClass('active');
      $($contentBlocks.get(index)).addClass('active');
    });
  });
}