
$('.closecircle').click(function () {
  $(this).hide(anim_duration);
  $('.timeline-info').hide(anim_duration);
});

$('g.circle').click(function () {
  let $this = $(this);

  let svg = $('.section__timeline .container svg');
  let top = -(
    svg.height() -
    ($this.position().top - $('.section__timeline .container').position().top)
  );
  let ingressNumber = 1;
  let info = $('.timeline-info');
  $this.find('text').each(function () {
    let text = $(this).text();
    if ($(this).hasClass('date')) {
      if ($(this).hasClass('all') || $(this).hasClass(language)) {
        info.find('.date').html(text);
      }
    } else {
      if ($(this).hasClass(language)) {
        info.find('.ingress' + ingressNumber).html(text);
        ingressNumber++;
      }
    }
  });
  info.css('top', top + 38 + 'px');
  info.show(anim_duration);

  let circle = $this.find('use');
  let x = circle.attr('x');
  let y = circle.attr('y');
  $('.closecircle').attr('transform', 'translate(' + x + ' ' + y + ')');
  $('.closecircle').show(anim_duration);
});

$('.section__timeline .timeline .' + language).show(0);
$('.section__timeline .timeline .all').show(0);
