const successs = document.getElementById('redirectUrlSubscriptionSuccess');
const failed = document.getElementById('redirectUrlSubscriptionFailed');

let currentUrl = window.location.href;

let language = document.getElementsByTagName('html')[0].getAttribute('lang');
if (language == 'nb-NO' || language == 'no') {
  if (successs) {
    successs.setAttribute('value', currentUrl + '/skjema-suksess');
  }
  if (failed) {
    failed.setAttribute('value', currentUrl + '/skjema-feilet');
  }
} else {
  if (successs) {
    successs.setAttribute('value', currentUrl + '/form-success');
  }
  if (failed) {
    failed.setAttribute('value', currentUrl + '/form-failed');
  }
}