const searchPage = document.querySelector('body.search');
const anim_duration = 200;

$('.websearch').click(function () {

  if ($(this).hasClass('open')) {
    $('.sicon').show();
    $('.close-x').hide();
    $('.websearchwrapper').slideUp(anim_duration);
  } else {
    $('.sicon').hide();
    $('.close-x').show();
    $('.websearchwrapper').slideDown(anim_duration);
    $('#websearch').focus();
  }
  $(this).toggleClass('open');
});

if(searchPage) {
    let filterTypes = searchPage.querySelectorAll('.type-filter a');
    let posts = searchPage.querySelectorAll('.search-page__hit');
    let $searchInput = $('header').find('.websearchwrapper form input.search-autocomplete');
    let $inputValue = $searchInput.val();
    $searchInput.val('');
    $searchInput.val($inputValue);
    if(filterTypes.length > 0) {
        filterTypes.forEach(function(type) {
            type.addEventListener('click', function(e) {
                e.preventDefault();
                let post_type = this.getAttribute('data-type');
                let separators = searchPage.querySelectorAll('.hr');
                searchPage.querySelector('.type-filter a.active').classList.remove('active');
                this.classList.add('active');
                posts.forEach(function(post) {
                    if(post_type == 'all') {
                        post.classList.remove('hide'); 
                        post.classList.add('show');
                        separators.forEach(function(separator) {
                            separator.classList.remove('hide');
                        });
                    }
                    else {
                        if(post.classList.contains(post_type)) {
                            post.classList.remove('hide'); 
                            post.classList.add('show'); 
                        }
                        else {
                            post.classList.remove('show'); 
                            post.classList.add('hide'); 
                        }
                    }
                });
                let displayedPosts = searchPage.querySelectorAll('.search-page__hit.show');
                displayedPosts[displayedPosts.length - 1].nextElementSibling.classList.add('hide');

            });
        })
    }
}