// Projects
function projectClickHandler(el, parent, selector) {
  el.addEventListener('click', function () {
    let child = parent.querySelector(selector);

    if (child.classList.contains('active')) {
      child.classList.remove('active');
      el.classList.remove('active');
    } else {
      child.classList.add('active');
      el.classList.add('active');
    }
  });
}

let projectGroups = document.querySelectorAll('.section__projects_group');
Array.prototype.forEach.call(projectGroups, function (pg, i) {
  let groups = pg.querySelectorAll('h3');
  Array.prototype.forEach.call(groups, function (group, i) {
    projectClickHandler(group, pg, 'ul');
  });

  let projects = pg.querySelectorAll('ul li');
  Array.prototype.forEach.call(projects, function (project, i) {
    let headers = project.querySelectorAll('h4');
    Array.prototype.forEach.call(headers, function (p, i) {
      projectClickHandler(p, project, '.project-description');
    });
  });
});