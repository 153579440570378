  // Toggle team bios
$('.person a.more').click(function () {
  let $this = $(this);
  let less = $this.next();
  let bio = $this.next().next();
  bio.show(500);
  less.click(function () {
    bio.hide(500);
    $this.css('display', 'inline');
    less.css('display', 'none');
  });
  $this.css('display', 'none');
  less.css('display', 'inline');
});